import * as React from "react"
import {NextRoundMatchDto, NextRoundScheduleDto} from "../../../api/dtos"
import DateUtil from "../../../util/DateUtil"
import {mobile} from "../../../App"
import Table from "../../../components/Table"
import TableRow from "../../../components/TableRow"
import TableText from "../../../components/TableText"
import RatingUtil from "../../../util/RatingUtil";

interface Props {
    schedule: NextRoundScheduleDto
}

class NextRoundSchedule extends React.Component<Props> {

    render() {
        return (
            <Table>
                {this.props.schedule.matches.map((match,i) => this.getMatch(match, this.props.schedule.matches.length === (i+1)))}
            </Table>);
    }

    getMatch(match: NextRoundMatchDto, lastRow: boolean) {
        const player1String = match.player1 ? match.player1 : "";
        const player2String = match.player2 ? match.player2 : "";

        return (
            <TableRow key={match.court + match.time.date + match.time.hour + match.time.minute} lastRow={lastRow}>
                <TableText left="1rem" mobLeft="0.5rem" mobFirstRow>{DateUtil.shortDayName(match.time.day)}</TableText>
                <TableText left="3rem" mobLeft="3.5rem" mobFirstRow>{match.time.date}</TableText>
                <TableText left="9rem" mobLeft="0.5rem"
                           mobSecondRow>kl {mobile ? match.time.hour : DateUtil.format(match.time.hour, match.time.minute)}</TableText>
                <TableText left="13.5rem" mobLeft="6rem" mobSecondRow>{match.court}</TableText>
                {mobile ?
                    <>
                        <TableText mobLeft="15rem" mobFirstRow>{player1String}</TableText>
                        <TableText mobLeft="15rem" mobSecondRow>{player2String}</TableText>
                    </>
                    :
                    <TableText left="17.5rem">{player1String} - {player2String}</TableText>
                }
                <TableText bgColor={NextRoundSchedule.getColor(match.player1FreqRating)} left="35rem"
                           mobLeft="32rem"><span style={{
                    width: "2rem",
                    display: "block",
                    textAlign: "center"
                }}>{match.player1FreqRating}</span></TableText>
                <TableText bgColor={NextRoundSchedule.getColor(match.player1TimeRating)} left="38rem"
                           mobLeft="36rem"><span style={{
                    width: "2rem",
                    display: "block",
                    textAlign: "center"
                }}>{match.player1TimeRating}</span></TableText>
                <TableText bgColor={NextRoundSchedule.getColor(match.player2TimeRating)} left="41rem"
                           mobLeft="40rem"><span style={{
                    width: "2rem",
                    display: "block",
                    textAlign: "center"
                }}>{match.player2TimeRating}</span></TableText>
                <TableText bgColor={NextRoundSchedule.getColor(match.player2FreqRating)} left="44rem"
                           mobLeft="44rem"><span style={{
                    width: "2rem",
                    display: "block",
                    textAlign: "center"
                }}>{match.player2FreqRating}</span></TableText>
            </TableRow>
        );
    }

    private static getColor(points: number): string {
        if (points < 5) {
            return "var(--panelBg)"
        }
        if (points < 11) {
            return RatingUtil.RATHER_NOT_COLOR;
        }
        return RatingUtil.CANT_COLOR;
    }
}

export default NextRoundSchedule;