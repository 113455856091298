import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {BookingIdDto, BookingsWithTimeRatingsDto, BookingWithTimeRatingsDto, MatchDetailsDto, TimeRating} from "../../api/dtos"
import {getFreeBookings, getMatchDetails, newBookingForMatch} from "../../api/api"
import DateUtil from "../../util/DateUtil"
import Spinner from "../../components/Spinner"
import {mobile} from "../../App"
import Table from "../../components/Table"
import TableRow from "../../components/TableRow"
import TableText from "../../components/TableText"
import TableButton, {ButtonType} from "../../components/TableButton"
import TennisBall from "../../components/TennisBall"
import {LinkButton} from "../../components/LinkButton"
import RatingColorBox from "../../components/RatingColorBox";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }> & RouteComponentProps<{ matchId: string }>

interface State {
    fatalError: boolean
    loading: boolean
    loadingBookings: boolean
    matchDetails?: MatchDetailsDto
    redirectTo?: string
    errorMessage?: string
    newTimes?: BookingsWithTimeRatingsDto
}

export default class ScheduleMatchTimePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            loadingBookings: true
        };
    }

    async componentDidMount() {
        if (this.props.session) {
            const {groupplayId, matchId} = this.props.match.params;
            try {
                const matchDetails = await getMatchDetails(groupplayId, matchId, this.props.session.sessionId);
                this.setState({matchDetails: matchDetails, loading: false});
                const newTimes: BookingsWithTimeRatingsDto = await getFreeBookings(groupplayId, matchId, this.props.session.sessionId);
                this.setState({newTimes: newTimes, loadingBookings: false})
            } catch (error: any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "PERMISSION_DENIED":
                        //TODO! nicer handling..
                        this.setState({fatalError: true, loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        } else {
            console.log("No session!")
        }
    }

    render() {
        const {groupplayId, matchId} = this.props.match.params;
        const matchIdNumber = parseInt(matchId);
        const {matchDetails, newTimes} = this.state;
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        if (!this.props.session) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>);
        }
        const smallSize = mobile ? "3rem" : "1rem";

        return (
            <BasePage {...this.props} loading={this.state.loading} sessionRequired={true}
                      fatalError={this.state.fatalError}>
                {matchDetails &&
                    <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {this.renderMatchInfo(matchDetails, smallSize)}
                        {this.state.loadingBookings && <>
                            <span style={{fontSize: smallSize, margin: "auto"}}>Hämtar bokningar från</span>
                            <span style={{fontSize: smallSize, margin: "auto"}}>bokningssystemet...</span>
                            <Spinner/>
                        </>}
                        {newTimes && this.renderNewTimes(newTimes, groupplayId, matchIdNumber)}
                    </div>}
            </BasePage>
        );
    }

    private renderMatchInfo = (matchDetails: MatchDetailsDto, smallSize: string) => {
        const containerWidth = mobile ? "80vw" : "20rem";
        const fontSize = mobile ? "4.5rem" : "2rem";
        const smallFontSize = mobile ? "2rem" : "1rem";
        const name1 = matchDetails.player1.firstName + " " + matchDetails.player1.lastName;
        const name2 = matchDetails.player2.firstName + " " + matchDetails.player2.lastName;
        const email1 = matchDetails.player1.email;
        const email2 = matchDetails.player2.email;
        const phone1 = matchDetails.player1.phone;
        const phone2 = matchDetails.player2.phone;
        return (
            <div style={{
                width: containerWidth, display: "flex", flexDirection: "column", alignItems: "center",
                fontSize: fontSize, marginBottom: "1rem"
            }}>
                <span>{name1} {matchDetails.ballPlayer === 1 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone1}>{phone1}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone1}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0}}
                   href={"mailto:" + name1 + "<" + email1 + ">"}>{email1}</a>
                <span style={{fontSize: smallSize, marginTop: "1.5rem", marginBottom: "1.5rem"}}>vs</span>
                <span>{name2} {matchDetails.ballPlayer === 2 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone2}>{phone2}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone2}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0, marginBottom: "2rem"}}
                   href={"mailto:" + name2 + "<" + email2 + ">"}>{email2}</a>
                {matchDetails.timePlace && matchDetails.timePlace.ownBooking &&
                    <span style={{fontSize: smallSize}}> <span className="pulsate-white">OBS!</span> Egen bokad tid:</span>}
                {matchDetails.timePlace && <span
                    style={{fontSize: smallSize}}>{DateUtil.dayName(matchDetails.timePlace.time.day)} {matchDetails.timePlace.time.date}</span>}
                {matchDetails.timePlace ? <span
                        style={{fontSize: smallSize}}>kl {DateUtil.format(matchDetails.timePlace.time.hour, matchDetails.timePlace.time.minute)} {matchDetails.timePlace.court}</span> :
                    <span style={{fontSize: smallSize}}> Obokad </span>}
                {matchDetails.ballPlayer &&
                    <span style={{fontSize: smallSize, margin: "1rem"}}><TennisBall size="1em"/> = ska ha bollar med sig.</span>}
            </div>
        );
    }

    private renderNewTimes = (newTimes: BookingsWithTimeRatingsDto, groupplayId: string, matchId: number) => {
        const smallSize = mobile ? "3rem" : "1rem";
        const fontSize = mobile ? "4.5rem" : "2rem";
        if(newTimes.awesomeTimes.length === 0 && newTimes.okTimes.length === 0 && newTimes.badTimes.length === 0) {
            return <div style={{textAlign: "center", marginTop: "2rem"}}>
                <div style={{fontSize: fontSize}}>Det finns inga lediga tider tillgängliga..</div>
                <LinkButton style={{margin: "2rem", fontSize: smallSize}} onClick={() => this.goBack(groupplayId, matchId)}/>
            </div>
        }
        return (
            <>
                <LinkButton style={{margin: "0.5rem", fontSize: smallSize}}
                            onClick={() => this.goBack(groupplayId, matchId)}/>
                <div style={{display: "flex", justifyContent: "space-evenly", fontSize: mobile ? "2rem" : "1rem", width: "100%"}}>
                    <RatingColorBox rating={TimeRating.GOOD} textDesc={true}/>
                    <RatingColorBox rating={TimeRating.MEDIUM} textDesc={true}/>
                    <RatingColorBox rating={TimeRating.RATHER_NOT} textDesc={true}/>
                    <RatingColorBox rating={TimeRating.CANT} textDesc={true}/>
                </div>
                {newTimes.awesomeTimes.length > 0 &&
                <Table heading={newTimes.awesomeTimes.length === 1 ?
                    "Här är en tid som passar båda bra:" : "Här är tider som passar båda bra:"}>
                    {newTimes.awesomeTimes.map((b,i) =>
                        this.getBooking(b, groupplayId, matchId, newTimes.awesomeTimes.length === (i+1)))}
                </Table>}
                {newTimes.okTimes.length > 0 &&
                <Table heading={newTimes.okTimes.length === 1 ?
                    "Här är en tid som är helt okej:" : "Här är tider som är helt okej:"}>
                    {newTimes.okTimes.map((b,i) =>
                        this.getBooking(b, groupplayId, matchId, newTimes.okTimes.length === (i+1)))}
                </Table>}
                {newTimes.badTimes.length > 0 &&
                    <Table heading={newTimes.badTimes.length === 1 ?
                        "Här är en tid som kanske inte är jättebra:" : "Här är tider som kanske inte är jättebra:"}>
                    {newTimes.badTimes.map((b,i) =>
                        this.getBooking(b, groupplayId, matchId, newTimes.badTimes.length === (i+1)))}
                </Table>}
            </>);
    }

    private getBooking = (bwtr: BookingWithTimeRatingsDto, groupplayId: string, matchId: number, lastRow: boolean) => {
        const rowHeight = mobile ?
            (Math.max(0, bwtr.closeMatches.length - 1) * 1.8 + 6.5) + "rem" :
            (Math.max(0, bwtr.closeMatches.length - 2) * 0.8 + 2) + "rem";

        return (<TableRow key={bwtr.booking.id} height={rowHeight} lastRow={lastRow}>
            <TableText left="1rem" mobLeft="0.5rem"
                       mobFirstRow>{DateUtil.shortDayName(bwtr.booking.time.day)}</TableText>
            <TableText left="3rem" mobLeft="3.5rem" mobFirstRow>{bwtr.booking.time.date}</TableText>
            <TableText left="9rem" mobLeft="15rem"
                       mobFirstRow>kl {mobile ? bwtr.booking.time.hour : DateUtil.format(bwtr.booking.time.hour, bwtr.booking.time.minute)}</TableText>
            <TableText left="13.5rem" mobLeft="20rem" mobFirstRow>{bwtr.booking.court}</TableText>

            <TableText left="17rem" mobLeft="27rem" mobFirstRow>
                <RatingColorBox rating={bwtr.ratingPlayer1} />
            </TableText>
            <TableText left="19rem" mobLeft="30rem" mobFirstRow>
                <RatingColorBox rating={bwtr.ratingPlayer2} />
            </TableText>
            <TableText left="21rem" mobLeft="0">
                <div style={{
                    fontSize: mobile ? "85%" : "70%",
                    maxWidth: mobile ? "34rem" : "23rem",
                    marginTop: mobile ? "1.5rem" : "-0.25rem",
                    fontStyle: "italic"
                }}>{bwtr.closeMatches.map((str, idx) => {
                        return (<>{str} <br/></>);
                    }
                )}</div>
            </TableText>

            <TableButton type={ButtonType.BOOK} right="0" mobRight="0"
                         onClick={() => this.newBookingForMatch(groupplayId, matchId, bwtr.booking.id, bwtr.booking.time.date, bwtr.booking.time.hour)}/>
        </TableRow>);

    }

    private newBookingForMatch = async (groupplayId: string, matchId: number, bookingId: string, bookingDate: string, bookingHour: number) => {
        const {session} = this.props;
        if (session) {
            this.setState({loading: true});
            try {
                const bookingIdDto: BookingIdDto = {
                    bookingId: bookingId,
                    date: bookingDate,
                    hour: bookingHour
                };
                await newBookingForMatch(groupplayId, matchId, bookingIdDto, session.sessionId);
                this.setState({loading: false, redirectTo: "/gruppspel/" + groupplayId + "/schema"});
            } catch (error: any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    case "BOOKING_ALREADY_USED":
                        //TODO: Show nicer message maybe?? but wont happen often...
                        this.setState({fatalError: true, loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }

        }
    }

    private goBack = (groupplayId: string, matchId: number) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/match/" + matchId});
    }
}
