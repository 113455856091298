import * as React from "react"
import {CSSProperties} from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {MatchDetailsDto, SaveResultDto, SetResultDto} from "../../api/dtos"
import {getMatchDetails, saveMatchResult} from "../../api/api"
import Checkbox from "../../components/Checkbox"
import {ErrorMessage} from "../../components/ErrorMessage"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }> & RouteComponentProps<{ matchId: string }>

interface State {
    fatalError: boolean
    loading: boolean
    matchDetails?: MatchDetailsDto
    dropdown: number
    setResults: SetResultDto[]
    player1Wo: boolean
    player2Wo: boolean
    player1WoNoShow: boolean
    player2WoNoShow: boolean
    redirectTo?: string
    errorMessage?: string
}

export default class ReportResultPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            fatalError: false,
            loading: true,
            dropdown: 0,
            setResults: [],
            player1Wo: false,
            player2Wo: false,
            player1WoNoShow: false,
            player2WoNoShow: false
        }
    }

    async componentDidMount() {
        if (this.props.session) {
            const {groupplayId, matchId} = this.props.match.params
            try {
                const matchDetails = await getMatchDetails(groupplayId, matchId, this.props.session.sessionId)
                let {player1Wo, player2Wo, player1WoNoShow, player2WoNoShow, setResults} = this.state
                if (matchDetails.result) {
                    setResults = matchDetails.result.sets
                    player1Wo = matchDetails.result.player1Wo
                    player2Wo = matchDetails.result.player2Wo
                    player1WoNoShow = matchDetails.result.player1WoNoShow
                    player2WoNoShow = matchDetails.result.player2WoNoShow
                }

                for (let i = setResults.length; i < matchDetails.rules.nrOfSets; i++) {
                    setResults.push({player1Games: undefined, player2Games: undefined})
                }

                this.setState({
                    matchDetails: matchDetails,
                    player1Wo: player1Wo,
                    player2Wo: player2Wo,
                    player1WoNoShow: player1WoNoShow,
                    player2WoNoShow: player2WoNoShow,
                    setResults: setResults,
                    loading: false
                })
            } catch (error: any) {
                console.log('ERROR' + error)
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false})
                        break
                }
            }
        } else {
            console.log("No session!")
        }
    }

    render() {
        const {groupplayId} = this.props.match.params
        const {matchDetails, player1Wo, player2Wo, player1WoNoShow, player2WoNoShow} = this.state
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>)
        }
        if (!this.props.session) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>)
        }
        if (matchDetails) {
            const ply1 = matchDetails.player1
            const ply2 = matchDetails.player2

            const fontSize = mobile ? "3rem" : "1.5rem"
            const smallSize = mobile ? "2rem" : "1rem"
            const backSize = mobile ? "3rem" : "1rem"
            const nameWidth = mobile ? "28rem" : "14rem"
            const rowMargin = mobile ? "1.5rem 0" : "0.5rem 0"

            return (
                <BasePage {...this.props} loading={this.state.loading} sessionRequired={true}
                          fatalError={this.state.fatalError}>
                    {matchDetails && <div style={{margin: "auto"}}>
                        <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                            <div style={{fontSize: smallSize}}>Resultat:</div>
                            <div style={{fontSize: fontSize}}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: rowMargin
                                }}>
                                    <div style={{
                                        width: nameWidth,
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}>{ply1.firstName} {ply1.lastName}</div>
                                    {this.playerSetBoxes(1)}
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    margin: rowMargin
                                }}>
                                    <div style={{
                                        width: nameWidth,
                                        overflow: "hidden",
                                        whiteSpace: "nowrap"
                                    }}>{ply2.firstName} {ply2.lastName}</div>
                                    {this.playerSetBoxes(2)}
                                </div>
                            </div>

                            <div style={{margin: "0.5rem", fontSize: smallSize, position: "relative"}}>
                                <Checkbox onChange={this.player1WoClicked} checked={player1Wo}
                                          disabled={player2Wo || player1WoNoShow || player2WoNoShow}
                                          text={ply1.firstName + " " + ply1.lastName + " lämnade WO"}
                                          id="player1WoCheckbox"/>
                            </div>
                            <div style={{margin: "0.5rem", fontSize: smallSize, position: "relative"}}>
                                <Checkbox onChange={this.player2WoClicked} checked={player2Wo}
                                          disabled={player1Wo || player1WoNoShow || player2WoNoShow}
                                          text={ply2.firstName + " " + ply2.lastName + " lämnade WO"}
                                          id="player2WoCheckbox"/>
                            </div>
                            <div style={{margin: "0.5rem", fontSize: smallSize, position: "relative"}}>
                                <Checkbox onChange={this.player1WoNoShowClicked} checked={player1WoNoShow}
                                          disabled={player1Wo || player2Wo || player2WoNoShow}
                                          text={ply1.firstName + " " + ply1.lastName + " lämnade WO (ej föranmäld)"}
                                          id="player1WoNoShowCheckbox"/>
                            </div>
                            <div style={{margin: "0.5rem", fontSize: smallSize, position: "relative"}}>
                                <Checkbox onChange={this.player2WoNoShowClicked} checked={player2WoNoShow}
                                          disabled={player1Wo || player2Wo || player1WoNoShow}
                                          text={ply2.firstName + " " + ply2.lastName + " lämnade WO (ej föranmäld)"}
                                          id="player2WoNoShowCheckbox"/>
                            </div>
                            <ErrorMessage text={this.state.errorMessage} desktopWidth={"22rem"}/>
                            <button style={{margin: "0 1rem", width: "min-content", alignSelf: "flex-end"}}
                                    onClick={this.handleSaveClick}> Spara
                            </button>
                            <LinkButton style={{margin: "1rem", alignSelf: "flex-end", fontSize: backSize}}
                                        onClick={() => this.goBack(groupplayId, matchDetails.id)}/>
                        </div>
                    </div>}
                </BasePage>
            )
        }
        return null
    }

    private playerSetBoxes(playerNr: number) {
        const setBoxSize = mobile ? "4rem" : "2rem"
        const borderRadius = mobile ? "0.2rem" : "0.1rem"
        const boxStyle: CSSProperties = {
            backgroundColor: "var(--buttonBg)",
            color: "black",
            cursor: "pointer",
            margin: "0.75rem",
            position: "relative",
            width: setBoxSize,
            height: setBoxSize,
            borderRadius: borderRadius
        }
        const spanStyle: CSSProperties = mobile ?
            {margin: "auto", position: "absolute", top: "0.2rem", left: "1.2rem"} :
            {margin: "auto", position: "absolute", top: "0.1rem", left: "0.5rem"}

        const sets: React.ReactElement[] = []
        const setResults: SetResultDto[] = this.state.setResults
        for (let i = 0; i < setResults.length; i++) {
            if (this.state.player1Wo || this.state.player2Wo || this.state.player1WoNoShow || this.state.player2WoNoShow) {
                sets.push(<div key={playerNr + "-" + i} style={{...boxStyle, backgroundColor: "var(--buttonDisabled)"}}></div>)
            } else if (playerNr === 1) {
                sets.push(<div key={playerNr + "-" + i} style={boxStyle} onClick={() => this.boxClicked(playerNr, i)}>
                    <span style={spanStyle}>{setResults[i].player1Games}</span>
                </div>)
            } else if (playerNr === 2) {
                sets.push(<div key={playerNr + "-" + i} style={boxStyle} onClick={() => this.boxClicked(playerNr, i)}>
                    <span style={spanStyle}>{setResults[i].player2Games}</span>
                </div>)
            }
        }
        return sets
    }

    private boxClicked = (playerNr: number, set: number) => {
        const setResults = this.state.setResults
        const newSetResults: SetResultDto[] = Object.assign([], setResults)
        if (playerNr === 1) {
            newSetResults[set] = {
                ...setResults[set],
                player1Games: this.changePlayerGames(setResults[set].player1Games)
            }
            this.setState({setResults: newSetResults})
        } else {
            newSetResults[set] = {
                ...setResults[set],
                player2Games: this.changePlayerGames(setResults[set].player2Games)
            }
            this.setState({setResults: newSetResults})
        }


    }

    private changePlayerGames = (oldValue?: number) => {
        if (this.state.matchDetails) {
            const gamesPerSet = this.state.matchDetails.rules.gamesPerSet
            if (oldValue !== undefined) {
                let value = oldValue + 1
                if (value > gamesPerSet) {
                    return undefined
                }
                return value
            }
            return 0
        }
        return undefined
    }

    private player1WoClicked = (checked: boolean) => {
        this.setState({player1Wo: checked})
    }

    private player2WoClicked = (checked: boolean) => {
        this.setState({player2Wo: checked})
    }

    private player1WoNoShowClicked = (checked: boolean) => {
        this.setState({player1WoNoShow: checked})
    }

    private player2WoNoShowClicked = (checked: boolean) => {
        this.setState({player2WoNoShow: checked})
    }

    private goBack = (groupplayId: string, matchId: number) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/match/" + matchId})
    }

    private handleSaveClick = async () => {
        const {player1Wo, player2Wo, player1WoNoShow, player2WoNoShow, setResults, matchDetails} = this.state
        if (this.props.session && matchDetails &&
            (player1Wo || player2Wo || player1WoNoShow || player2WoNoShow || setResults.length > 0)) {
            const {groupplayId} = this.props.match.params
            let sets: SetResultDto[] = []
            if (setResults.length > 0 && !player1Wo && !player2Wo && !player1WoNoShow && !player2WoNoShow) {
                for (const setResult of setResults) {
                    if (setResult.player1Games !== undefined || setResult.player2Games !== undefined) {
                        sets.push({player1Games: setResult.player1Games, player2Games: setResult.player2Games})
                    }
                }
            }
            const saveResultDto: SaveResultDto = {
                player1Wo: player1Wo,
                player2Wo: player2Wo,
                player1WoNoShow: player1WoNoShow,
                player2WoNoShow: player2WoNoShow,
                sets: sets
            }
            try {
                await saveMatchResult(saveResultDto, matchDetails.id, groupplayId, this.props.session.sessionId)
            } catch (error: any) {
                console.log('error caught:' + error)
                console.log('error type:' + error.type)
                switch (error.type) {
                    case 'INVALID_RESULT':
                        console.log('errorMessage=' + error.message)
                        this.setState({errorMessage: error.message, fatalError: false})
                        break
                    default:
                        this.setState({errorMessage: "", fatalError: true})
                        break
                }
                return
            }
            this.setState({redirectTo: "/gruppspel/" + groupplayId + "/schema"})
        } else {
            this.setState({errorMessage: "", fatalError: true})
        }
    }
}