import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {BookingsWithTimeRatingsDto, MatchDetailsDto, TimePlaceRequestDto} from "../../api/dtos"
import {clearMatchTimePlace, getMatchDetails, saveOwnBookingForMatch} from "../../api/api"
import DateUtil from "../../util/DateUtil"
import Spinner from "../../components/Spinner"
import {mobile} from "../../App"
import TennisBall from "../../components/TennisBall"
import DateInput from "../../components/DateInput"
import TextInput from "../../components/TextInput"
import HourInput from "../../components/HourInput"
import {LinkButton} from "../../components/LinkButton"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }> & RouteComponentProps<{ matchId: string }>

enum MatchPageState {
    MENU = "MENU",
    NEW_TIMES = "NEW_TIMES",
    LOADING_BOOKINGS = "LOADING_BOOKINGS",
    OWN_BOOKING = "OWN_BOOKING"
}

interface State {
    fatalError: boolean
    loading: boolean
    matchDetails?: MatchDetailsDto
    redirectTo?: string
    errorMessage?: string
    newTimes?: BookingsWithTimeRatingsDto,
    state: MatchPageState
    ownBookingDate?: string
    ownBookingTime?: number
    ownBookingCourt?: string
    ownBookingDateValid: boolean
    ownBookingTimeValid: boolean
    ownBookingError?: string
}

export default class MatchPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            state: MatchPageState.MENU,
            ownBookingTimeValid: false,
            ownBookingDateValid: false
        };
    }

    async componentDidMount() {
        if (this.props.session) {
            const {groupplayId, matchId} = this.props.match.params;
            try {
                const matchDetails = await getMatchDetails(groupplayId, matchId, this.props.session.sessionId);
                this.setState({matchDetails: matchDetails, loading: false});
                if (matchDetails.timePlace && matchDetails.timePlace.ownBooking) {
                    this.setState({
                        ownBookingTime: matchDetails.timePlace.time.hour,
                        ownBookingDate: matchDetails.timePlace.time.date,
                        ownBookingCourt: matchDetails.timePlace.court,
                        ownBookingDateValid: true,
                        ownBookingTimeValid: true
                    })
                }

            } catch (error: any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "PERMISSION_DENIED":
                        //TODO! nicer handling..
                        this.setState({fatalError: true, loading: false});
                        break;
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        } else {
            console.log("No session!")
        }
    }

    render() {
        const {groupplayId, matchId} = this.props.match.params;
        const matchIdNumber = parseInt(matchId);
        const {matchDetails, state} = this.state;
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        if (!this.props.session) {
            return (<Redirect to={'/gruppspel/' + groupplayId}/>);
        }
        const smallSize = mobile ? "3rem" : "1rem";

        return (
            <BasePage {...this.props} loading={this.state.loading} sessionRequired={true}
                      fatalError={this.state.fatalError}>
                {matchDetails &&
                    <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {this.renderMatchInfo(matchDetails, smallSize)}

                        {state === "LOADING_BOOKINGS" && <>
                            <Spinner/>
                            <span style={{fontSize: smallSize, margin: "auto"}}>Hämtar bokningar från</span>
                            <span style={{fontSize: smallSize, margin: "auto"}}>bokningssystemet...</span>
                        </>}
                        {state === "MENU" && this.renderButtons(matchDetails, groupplayId, matchIdNumber, smallSize)}
                        {state === "OWN_BOOKING" && this.renderOwnBookingForm()}
                    </div>}
            </BasePage>
        );
    }

    private renderMatchInfo = (matchDetails: MatchDetailsDto, smallSize: string) => {
        const containerWidth = mobile ? "80vw" : "20rem";
        const fontSize = mobile ? "4.5rem" : "2rem";
        const smallFontSize = mobile ? "2rem" : "1rem";
        const name1 = matchDetails.player1.firstName + " " + matchDetails.player1.lastName;
        const name2 = matchDetails.player2.firstName + " " + matchDetails.player2.lastName;
        const email1 = matchDetails.player1.email;
        const email2 = matchDetails.player2.email;
        const phone1 = matchDetails.player1.phone;
        const phone2 = matchDetails.player2.phone;
        return (
            <div style={{
                width: containerWidth, display: "flex", flexDirection: "column", alignItems: "center",
                fontSize: fontSize, marginBottom: "1rem"
            }}>
                <span>{name1} {matchDetails.ballPlayer === 1 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone1}>{phone1}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone1}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0}}
                   href={"mailto:" + name1 + "<" + email1 + ">"}>{email1}</a>
                <span style={{fontSize: smallSize, marginTop: "1.5rem", marginBottom: "1.5rem"}}>vs</span>
                <span>{name2} {matchDetails.ballPlayer === 2 && <TennisBall size="1em" marginBottom="-0.5rem"/>}</span>
                {mobile ?
                    <a style={{fontSize: smallFontSize}} href={"tel:" + phone2}>{phone2}</a>
                    :
                    <span style={{fontSize: smallFontSize}}>{phone2}</span>}
                <a style={{fontSize: smallFontSize, marginTop: mobile ? "1rem" : 0, marginBottom: "2rem"}}
                   href={"mailto:" + name2 + "<" + email2 + ">"}>{email2}</a>
                {matchDetails.timePlace && matchDetails.timePlace.ownBooking &&
                    <span style={{fontSize: smallSize}}> <span className="pulsate-white">OBS!</span> Egen bokad tid:</span>}
                {matchDetails.timePlace && <span
                    style={{fontSize: smallSize}}>{DateUtil.dayName(matchDetails.timePlace.time.day)} {matchDetails.timePlace.time.date}</span>}
                {matchDetails.timePlace ? <span
                        style={{fontSize: smallSize}}>kl {DateUtil.format(matchDetails.timePlace.time.hour, matchDetails.timePlace.time.minute)} {matchDetails.timePlace.court}</span> :
                    <span style={{fontSize: smallSize}}> Obokad </span>}
                {matchDetails.ballPlayer &&
                    <span style={{fontSize: smallSize, margin: "1rem"}}><TennisBall size="1em"/> = ska ha bollar med sig.</span>}
            </div>
        );
    }

    private renderButtons = (matchDetails: MatchDetailsDto, groupplayId: string, matchId: number, smallSize: string) => {
        const buttonWidth = mobile ? "80vw" : "13rem";
        return (<>
                {matchDetails.resultEditable &&
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.resultClicked(groupplayId, matchId)}>Resultat</button>}
                {matchDetails.movable &&
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.setState({redirectTo: "/gruppspel/" + groupplayId + "/match/" + matchId + "/schedule"})}>
                        {matchDetails.timePlace === undefined ? "Boka matchtid" : "Byt matchtid"}
                    </button>}
                {matchDetails.movable && matchDetails.timePlace &&
                    <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                            onClick={() => this.clearMatchTimePlaceClicked(groupplayId, matchId)}>Ledigställ tid</button>}
                {matchDetails.movable && <button style={{marginLeft: 0, marginRight: 0, width: buttonWidth}}
                                                 onClick={() => this.ownBookingClicked()}>Egen bokning</button>}
                <LinkButton style={{marginTop: "1rem", fontSize: smallSize}} onClick={() => this.goBack(groupplayId)}/>
            </>
        );
    }

    private renderOwnBookingForm = () => {
        const smallSize = mobile ? "3rem" : "1rem";
        const {ownBookingDate, ownBookingTime, ownBookingCourt} = this.state;
        return (
            <>
                <div style={{marginTop: "1rem", fontSize: smallSize}}>Egen bokad tid att</div>
                <div style={{fontSize: smallSize}}>använda till denna match:</div>
                {this.state.ownBookingError && <div style={{fontSize: smallSize, color: "red"}}>{this.state.ownBookingError}</div>}

                <DateInput onChange={this.onOwnBookingDateChange} value={ownBookingDate}/>
                <HourInput onChange={this.onOwnBookingTimeChange} value={ownBookingTime}/>
                <TextInput onChange={this.onOwnBookingCourtChange} placeHolder={"bana"} width="3rem" mobileWidth="12rem"
                           value={ownBookingCourt}/>

                <button onClick={this.saveOwnBooking} disabled={this.ownBookingSaveDisabled()}>Spara</button>

                <LinkButton style={{margin: "0.5rem", fontSize: smallSize}} onClick={() => this.setState({state: MatchPageState.MENU})}/>
            </>
        );
    }

    private saveOwnBooking = async () => {
        const {groupplayId, matchId} = this.props.match.params;
        const {ownBookingDate, ownBookingTime, ownBookingCourt} = this.state;
        if (this.props.session && ownBookingDate && ownBookingTime && ownBookingCourt) {
            const request: TimePlaceRequestDto = {
                date: ownBookingDate,
                time: ownBookingTime,
                court: ownBookingCourt
            };
            try {
                await saveOwnBookingForMatch(groupplayId, parseInt(matchId), request, this.props.session.sessionId)
                this.setState({redirectTo: "/gruppspel/" + groupplayId + "/schema"})
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    case "TIME_AFTER_ROUND_END":
                        this.setState({loading: false, ownBookingError: "Tiden är efter omgångens slut!"})
                        break
                    default:
                        this.setState({fatalError: true, loading: false})
                        break
                }
            }
        }
    }

    private ownBookingSaveDisabled = (): boolean => {
        const {
            ownBookingDateValid, ownBookingTimeValid, ownBookingCourt,
            ownBookingTime, ownBookingDate, matchDetails
        } = this.state;

        let ownBookingUnchanged = false;
        if (matchDetails && matchDetails.timePlace && matchDetails.timePlace.ownBooking) {
            ownBookingUnchanged = matchDetails.timePlace.court === ownBookingCourt &&
                matchDetails.timePlace.time.hour === ownBookingTime &&
                matchDetails.timePlace.time.date === ownBookingDate;
        }

        return ownBookingUnchanged || !ownBookingDateValid || !ownBookingTimeValid || ownBookingCourt === undefined || ownBookingCourt === '';
    }

    private onOwnBookingDateChange = (currentValue: string, valid: boolean) => {
        this.setState({ownBookingDate: currentValue, ownBookingDateValid: valid});
    }

    private onOwnBookingTimeChange = (currentValue: number, valid: boolean) => {
        this.setState({ownBookingTime: currentValue, ownBookingTimeValid: valid});
    }

    private onOwnBookingCourtChange = (currentValue: string) => {
        this.setState({ownBookingCourt: currentValue});
    }

    private resultClicked = (groupplayId: string, matchId: number) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/match/" + matchId + "/result"})
    }

    private ownBookingClicked = () => {
        this.setState({state: MatchPageState.OWN_BOOKING});
    }

    private clearMatchTimePlaceClicked = async (groupplayId: string, matchId: number) => {
        const {session} = this.props;
        if (session) {
            this.setState({loading: true});
            try {
                await clearMatchTimePlace(groupplayId, matchId, session.sessionId);
                this.setState({loading: false, redirectTo: "/gruppspel/" + groupplayId + "/schema"});
            } catch (error: any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }

        }
    }

    private goBack = (groupplayId: string) => {
        this.setState({redirectTo: "/gruppspel/" + groupplayId + "/schema"});
    }
}
