import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router";
import {getBookMatchReminderLink} from "../../api/api";
import {BookMatchReminderLinkDto, ErrorType, SessionDto} from "../../api/dtos"
import FullPageSpinner from "../../components/FullPageSpinner";
import FullPageMessage from "../../components/FullPageMessage";

interface BookMatchReminderPageProps {
    session?: SessionDto
    onSessionChange: (rememberMe: boolean, session?: SessionDto) => void
}

interface State {
    fullPageErrorMessage?: string
    loading: boolean
    errorMessage?: string
    fatalError: boolean
    linkDto?: BookMatchReminderLinkDto
}

type Props = BookMatchReminderPageProps & RouteComponentProps<{ linkId: string }>

export default class ScheduleMatchReminderPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true
        };
    }

    async componentDidMount() {
        const {linkId} = this.props.match.params;
        const session = this.props.session;
        try {
            let linkDto: BookMatchReminderLinkDto = await getBookMatchReminderLink(linkId);
            this.setState({
                linkDto: linkDto
            });
            setTimeout(() => {
                if (!session && linkDto.session) {
                    console.log('no session before, new session with invite!');
                    this.props.onSessionChange(false, linkDto.session);
                }
                if (session && linkDto.session && session.sessionId !== linkDto.session.sessionId) {
                    console.log('session before, but not same as invite.. using invite session!');
                    this.props.onSessionChange(true, undefined);
                    this.props.onSessionChange(false, linkDto.session);
                }
                if (session && !linkDto.session) {
                    console.log('session before, but not on invite.. logging out!');
                    this.props.onSessionChange(true, undefined);
                }
            }, 100);
        } catch (error: any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.INVALID_LINK_ID:
                    this.setState({fullPageErrorMessage: "Länken du klickade på är för gammal och inte giltig längre!", loading: false});
                    break;
                default:
                    this.setState({fullPageErrorMessage: error.type, loading: false});
                    break;
            }
        }
    }

    render() {
        const linkDto = this.state.linkDto;
        if (this.state.fullPageErrorMessage) {
            return (<FullPageMessage text={this.state.fullPageErrorMessage}/>);
        }
        if(linkDto && this.props.session) {
            return (<Redirect to={"/gruppspel/" + linkDto.groupplayId + "/match/" + linkDto.matchId + "/schedule"}/>);
        }
        return (<FullPageSpinner/>);
    }
}
