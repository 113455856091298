import {TimeRating} from "../api/dtos";

export default class RatingUtil {

    public static GOOD_COLOR: string = '#00AA00';
    public static MEDIUM_COLOR: string = '#FFFB11';
    public static RATHER_NOT_COLOR: string = '#FF8106';
    public static CANT_COLOR: string = '#FF0000';

    public static ratingToColor(rating: TimeRating): string {
        switch (rating) {
            case TimeRating.GOOD:
                return RatingUtil.GOOD_COLOR;
            case TimeRating.MEDIUM:
                return RatingUtil.MEDIUM_COLOR;
            case TimeRating.RATHER_NOT:
                return RatingUtil.RATHER_NOT_COLOR;
            case TimeRating.CANT:
                return RatingUtil.CANT_COLOR;
            default:
                return "?";
        }
    }

    public static ratingToString(rating: TimeRating) : string {
        switch (rating) {
            case TimeRating.GOOD:
                return "Passar bra";
            case TimeRating.MEDIUM:
                return "Går";
            case TimeRating.RATHER_NOT:
                return "Helst inte";
            case TimeRating.CANT:
                return "kan inte";
            default:
                return "?";
        }
    }
}
