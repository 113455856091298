import {TimeRating} from "../api/dtos";
import {mobile} from "../App";
import RatingUtil from "../util/RatingUtil";

interface Props {
    rating: TimeRating
    textDesc?: boolean
}

const RatingColorBox = ({rating, textDesc}: Props) => {

    const width = textDesc ? (mobile?"10rem":"6rem") : (mobile?"2rem":"1rem");
    const height = textDesc ? (mobile?"2.8rem":"1.3rem") : (mobile?"1.8rem":"0.8rem")

    return (
        <div style={{
            display:"inline-block",
            width: width,
            height: height,
            backgroundColor: RatingUtil.ratingToColor(rating),
            textAlign: "center",
            paddingTop: "0.2rem"
        }}>
            {textDesc && RatingUtil.ratingToString(rating)}
        </div>)
}

export default RatingColorBox