import * as React from "react"
import {CSSProperties} from "react"
import DateUtil from "../../util/DateUtil"
import {mobile} from "../../App"

interface Props {
    month: number
    year: number
    selectedDays: string[]
    onSelected: (date: string) => any
    onDeselected: (date: string) => any
}

export default class MonthDaySelector extends React.Component<Props> {

    render() {
        const {month, year} = this.props;
        const fontSize = mobile ? "3rem" : "1rem";
        const marginTop = mobile ? "2rem" : "0";
        return (
            <table style={{borderSpacing: "2px", fontSize: fontSize, marginTop: marginTop}}>
                <tbody>
                <tr>
                    <td colSpan={7}
                        style={{backgroundColor: "var(--buttonBg)", color: "black"}}>
                        {DateUtil.monthName(month)} {year}
                    </td>
                </tr>
                {this.getDayHeadingRow()}
                {this.renderRows(month, year)}
                </tbody>
            </table>
        );
    }

    renderRows(month: number, year: number) {
        const date: Date = new Date(year, month, 1, 12);
        let rows : React.ReactElement[] = [];
        let currentDay = 1;
        let cells : React.ReactElement[] = [];
        let rowKey = 1;
        const rowHeight = mobile ? "12vw" : "1.6rem";
        const crossCss: CSSProperties = mobile ? {
            position: "absolute",
            color: "red",
            fontSize: "6rem",
            top: "-0.5rem",
            left: "1.2rem"
        } : {
            position: "absolute",
            color: "red",
            fontSize: "1.5rem",
            top: "-0.1rem",
            left: "0.4rem"
        };

        //fill up before the month
        while (currentDay !== this.realDayIdx(date.getDay())) {
            cells.push(<td key={"b-" + currentDay}></td>);
            currentDay++;
        }

        while (date.getMonth() === month) {
            const dateString = DateUtil.localDateString(date);
            cells.push(<td key={dateString} onClick={() => this.dayClicked(dateString)}
                           style={{
                               backgroundColor: "var(--panelBg)",
                               textAlign: "center",
                               cursor: "pointer",
                               position: "relative"
                           }}>
                {this.selected(dateString) && <span style={crossCss}>X</span>}
                <span>{date.getDate()}</span>
            </td>);

            if (cells.length === 7) {
                rows.push(<tr style={{height: rowHeight}} key={rowKey++}>{cells}</tr>);
                cells = [];
            }
            date.setDate(date.getDate()+1);
        }

        //fill up after the month
        currentDay = this.realDayIdx(date.getDay());
        while (currentDay !== 7) {
            cells.push(<td key={"a-" + currentDay}></td>);
            currentDay++;
        }

        rows.push(<tr style={{height: rowHeight}} key={rowKey}>{cells}</tr>);
        return rows;
    }

    dayClicked(dateString: string) {
        if (this.selected(dateString)) {
            this.props.onDeselected(dateString);
        } else {
            this.props.onSelected(dateString);
        }
    }

    selected(dateString: string): boolean {
        return this.props.selectedDays.indexOf(dateString) !== -1;
    }

    getDayHeadingRow() {
        const width = mobile ? "12vw" : "1.6rem";
        const headingCellStyle: CSSProperties = {
            cursor: "pointer",
            fontWeight: "normal",
            backgroundColor: "var(--buttonBg)",
            color: "black",
            padding: "0.1rem",
            textAlign: "center",
            width: width
        };
        return <tr>
            <td style={headingCellStyle}>Må
            </td>
            <td style={headingCellStyle}>Ti
            </td>
            <td style={headingCellStyle}>On
            </td>
            <td style={headingCellStyle}>To
            </td>
            <td style={headingCellStyle}>Fr
            </td>
            <td style={headingCellStyle}>Lö
            </td>
            <td style={headingCellStyle}>Sö
            </td>
        </tr>
    }

    private realDayIdx(dayIndex: number): number {
        if (dayIndex === 0) {
            return 7;
        }
        return dayIndex;
    }
}
