import * as React from "react"
import {CSSProperties} from "react"
import {TimeRating, TimeRatingDto, WeekDay} from "../../api/dtos"
import {Redirect} from "react-router"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import {Exception} from "../../api/Exception"
import RatingUtil from "../../util/RatingUtil";

interface Props {
    ratings: TimeRatingDto[]
    onSave: (ratings: TimeRatingDto[]) => void
    redirectTo: string
}

interface State {
    firstTime: number
    lastTime: number
    originalRatings: TimeRating[][]
    ratings: TimeRating[][]
    saveDisabled: boolean
    redirectTo?: string
}

class TimeRatingsInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        let ratings: TimeRating[][] = [];
        let originalRatings: TimeRating[][] = [];
        let firstTime = 23, lastTime = 0;
        for (let ratingDto of this.props.ratings) {
            if (ratings[ratingDto.time] === undefined) {
                ratings[ratingDto.time] = [];
                originalRatings[ratingDto.time] = [];
            }
            if (ratingDto.time < firstTime) {
                firstTime = ratingDto.time;
            }
            if (ratingDto.time > lastTime) {
                lastTime = ratingDto.time;
            }
            ratings[ratingDto.time][this.toIndex(ratingDto.day)] = ratingDto.rating;
            originalRatings[ratingDto.time][this.toIndex(ratingDto.day)] = ratingDto.rating;
        }
        this.state = {
            originalRatings: originalRatings,
            ratings: ratings,
            firstTime: firstTime,
            lastTime: lastTime,
            saveDisabled: true
        };
        this.cellClicked = this.cellClicked.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
        this.dayClicked = this.dayClicked.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        const fontSize = mobile ? "3rem" : "1rem";
        const boxSize = mobile ? "2rem" : "0.5rem";
        const boxMargin = mobile ? "4rem" : "2rem";
        const buttonWidth = mobile ? "85vw" : "10rem";
        const backMargin = mobile ? "3rem 1.5rem" : "0.5rem";
        const tableMargin = mobile ? "1rem 0" : "0rem 3rem";
        const pageLayout: CSSProperties = mobile ?
            {display: "flex", flexDirection: "column"} :
            {display: "flex", flexDirection: "row", alignItems: "center", alignContent: "space-around"};
        return (
            <div style={pageLayout}>
                <div style={{fontSize: fontSize, textAlign: "center"}}>
                    Klicka i tabellen för att <br/> ändra dina tidsönskemål. <br/>
                    <div style={{
                        backgroundColor: RatingUtil.GOOD_COLOR,
                        width: boxSize,
                        height: boxSize,
                        display: "inline-block"
                    }}/> bra
                    <div style={{
                        backgroundColor: RatingUtil.MEDIUM_COLOR,
                        width: boxSize,
                        height: boxSize,
                        display: "inline-block",
                        marginLeft: boxMargin
                    }}/> går
                    <div style={{
                        backgroundColor: RatingUtil.RATHER_NOT_COLOR,
                        width: boxSize,
                        height: boxSize,
                        display: "inline-block",
                        marginLeft: boxMargin
                    }}/> helst inte
                </div>
                <table style={{borderSpacing: "2px", margin: tableMargin}}>
                    <tbody>
                    {this.getDayHeadingRow()}
                    {this.renderRows()}
                    {this.getDayHeadingRow()}
                    </tbody>
                </table>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", marginTop: boxMargin}}>
                    <button onClick={this.saveClicked} disabled={this.state.saveDisabled}
                            style={{width: buttonWidth}}>Spara
                    </button>
                    <LinkButton style={{fontSize: fontSize, margin: backMargin, textAlign: "right"}}
                                onClick={this.goBack}/>
                </div>
            </div>
        );
    }

    getDayHeadingRow() {
        const fontSize = mobile ? "3rem" : "1rem";
        const headingCellStyle: CSSProperties = {
            fontSize: fontSize,
            cursor: "pointer",
            fontWeight: "normal",
            backgroundColor: "var(--buttonBg)",
            color: "black",
            padding: "0.1rem",
            textAlign: "center"
        };
        return <tr>
            <td style={{backgroundColor: "var(--pageBg)"}}></td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.MONDAY)}>Må
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.TUESDAY)}>Ti
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.WEDNESDAY)}>On
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.THURSDAY)}>To
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.FRIDAY)}>Fr
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.SATURDAY)}>Lö
            </td>
            <td style={headingCellStyle}
                onClick={() => this.dayClicked(WeekDay.SUNDAY)}>Sö
            </td>
        </tr>
    }

    renderRows() {
        const fontSize = mobile ? "3rem" : "1rem";
        const {ratings, firstTime, lastTime} = this.state;
        let rows : React.ReactElement[] = [];
        for (let time = firstTime; time <= lastTime; time++) {
            rows.push(<tr key={'row_' + time}>
                <td onClick={() => this.timeClicked(time)}
                    style={{
                        fontSize: fontSize,
                        padding: "0 0.5rem",
                        cursor: "pointer",
                        backgroundColor: "var(--buttonBg)",
                        color: "black",
                        textAlign: "right"
                    }}
                    key={'heading_' + time}>{time}-{time + 1}</td>
                {this.renderRowCells(time, ratings[time])}
            </tr>);
        }
        return rows;
    }

    renderRowCells(time: number, dayRatings: TimeRating[]) {
        let cells : React.ReactElement[] = [];
        for (let day = 0; day < 7; day++) {
            const rating: TimeRating = dayRatings[day];
            let color = 'var(--panelTableLine)'
            switch (rating) {
                case TimeRating.GOOD:
                    color = RatingUtil.GOOD_COLOR;
                    break;
                case TimeRating.MEDIUM:
                    color = RatingUtil.MEDIUM_COLOR;
                    break;
                case TimeRating.RATHER_NOT:
                    color = RatingUtil.RATHER_NOT_COLOR;
                    break;
            }
            const cellWidth = mobile ? "10vw" : "1.6rem";
            const cellHeight = mobile ? "4rem" : "1.5rem";
            cells.push(
                <td key={day + '_' + time}
                    style={{
                        backgroundColor: color, cursor: "pointer",
                        height: cellHeight, padding: "0", width: cellWidth
                    }}
                    onClick={() => this.cellClicked(time, day)}>
                </td>);
        }
        return cells;
    }

    cellClicked(time: number, day: number) {
        const {ratings} = this.state;

        if (ratings[time][day] === TimeRating.GOOD) {
            ratings[time][day] = TimeRating.MEDIUM;
        } else if (ratings[time][day] === TimeRating.MEDIUM) {
            ratings[time][day] = TimeRating.RATHER_NOT;
        } else if (ratings[time][day] === TimeRating.RATHER_NOT) {
            ratings[time][day] = TimeRating.GOOD;
        }
        this.updateSaveButtonStatus();
        this.setState({ratings: ratings});
    }

    dayClicked(day: WeekDay) {
        const {ratings, firstTime, lastTime} = this.state;
        const dayIndex = this.toIndex(day);
        for (let time = firstTime; time <= lastTime; time++) {
            if (ratings[time][dayIndex] === TimeRating.GOOD) {
                ratings[time][dayIndex] = TimeRating.MEDIUM;
            } else if (ratings[time][dayIndex] === TimeRating.MEDIUM) {
                ratings[time][dayIndex] = TimeRating.RATHER_NOT;
            } else if (ratings[time][dayIndex] === TimeRating.RATHER_NOT) {
                ratings[time][dayIndex] = TimeRating.GOOD;
            }
        }
        this.updateSaveButtonStatus();
        this.setState({ratings: ratings});
    }

    timeClicked(time: number) {
        const {ratings} = this.state;

        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            if (ratings[time][dayIndex] === TimeRating.GOOD) {
                ratings[time][dayIndex] = TimeRating.MEDIUM;
            } else if (ratings[time][dayIndex] === TimeRating.MEDIUM) {
                ratings[time][dayIndex] = TimeRating.RATHER_NOT;
            } else if (ratings[time][dayIndex] === TimeRating.RATHER_NOT) {
                ratings[time][dayIndex] = TimeRating.GOOD;
            }
        }
        this.updateSaveButtonStatus();
        this.setState({ratings: ratings});
    }

    saveClicked() {
        const {ratings, originalRatings, firstTime, lastTime} = this.state;
        let toSave: TimeRatingDto[] = [];
        for (let time = firstTime; time <= lastTime; time++) {
            for (let day = 0; day < 7; day++) {
                if (ratings[time][day] !== originalRatings[time][day]) {
                    toSave.push({time: time, day: this.fromIndex(day), rating: ratings[time][day]})
                }
            }
        }
        this.props.onSave(toSave);
    }

    private updateSaveButtonStatus() {
        const {ratings, originalRatings, firstTime, lastTime, saveDisabled} = this.state;
        for (let time = firstTime; time <= lastTime; time++) {
            for (let day = 0; day < 7; day++) {
                if (ratings[time][day] !== originalRatings[time][day]) {
                    if (saveDisabled) {
                        this.setState({saveDisabled: false});
                    }
                    return;
                }
            }
        }
        if (!saveDisabled) {
            this.setState({saveDisabled: true});
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }

    private toIndex(day: WeekDay): number {
        switch (day) {
            case WeekDay.MONDAY:
                return 0;
            case WeekDay.TUESDAY:
                return 1;
            case WeekDay.WEDNESDAY:
                return 2;
            case WeekDay.THURSDAY:
                return 3;
            case WeekDay.FRIDAY:
                return 4;
            case WeekDay.SATURDAY:
                return 5;
            case WeekDay.SUNDAY:
                return 6;
        }
    }

    private fromIndex(day: number): WeekDay {
        switch (day) {
            case 0:
                return WeekDay.MONDAY;
            case 1:
                return WeekDay.TUESDAY;
            case 2:
                return WeekDay.WEDNESDAY;
            case 3:
                return WeekDay.THURSDAY;
            case 4:
                return WeekDay.FRIDAY;
            case 5:
                return WeekDay.SATURDAY;
            case 6:
                return WeekDay.SUNDAY;
            default:
                throw new Exception('weird day index:' + day)
        }
    }
}


export default TimeRatingsInput;